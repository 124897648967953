import "./styles/index.scss"

document.addEventListener("DOMContentLoaded", () => {
	for (const asset of document.getElementsByClassName("vite-dev-asset")) {
		asset.remove()
	}

	initCopyPanels()
})

function initCopyPanels() {
	const panels = document.querySelectorAll(".inline-copy-panel")
	for (const panel of panels) {
		const btn = panel.querySelector("button")
		const text = panel.querySelector("span")

		if (!btn || !text) {
			continue
		}

		const tooltip = document.createElement("div")
		tooltip.innerText = "Successfully copied to your clipboard!"
		tooltip.classList.add("clipboard-tooltip")

		panel.insertBefore(tooltip, panel.firstChild)

		const icon = btn.querySelector(".fa-regular")
		let clicking = false

		btn.addEventListener("click", async () => {
			if (clicking) {
				return
			}
			try {
				await navigator.clipboard.writeText(text.innerText)
				if (icon) {
					tooltip.style.opacity = "1"
					icon.classList.remove("fa-regular", "fa-copy")
					icon.classList.add("fa-solid", "fa-check")

					await new Promise((resolve) => setTimeout(resolve, 5_000))

					tooltip.style.opacity = "0"
					icon.classList.remove("fa-solid", "fa-check")
					icon.classList.add("fa-regular", "fa-copy")
				}
			} finally {
				clicking = false
			}
		})
	}
}
